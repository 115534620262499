import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  public isBannerMenu : boolean = false;
  constructor(public router  :Router) { }

  ngOnInit(): void {
  }
  public routingCall=()=>{
    this.router.navigate([' ']);
  }

public isShowMenu=()=>{
   if(this.isBannerMenu == true)
   {
     this.isBannerMenu = false;
   }
   else
   {
     this.isBannerMenu = true;
   }
}


}
