<div class="container-fluid"  style="border-bottom: 2px solid#F1F1F1">
  <div class="container">
    <div class="row p-2">
      <div class="col topBartextShow">
            <div>
             <small class="text-muted">Welcome to Buero Planet</small>
            </div>
            <div class="text-muted topBarShow">
              <a (click)="routingCall()" class="text-muted cursorpointer"> <small style="padding-left: 10px; padding-right: 10px;"><i class="fas fa-map-marker-alt"></i> &nbsp;&nbsp; Store Locator</small></a>
              <a (click)="routingCall()" class="text-muted cursorpointer"><small style="padding-left: 10px; padding-right: 10px;"><i class="fas fa-shopping-bag"></i> &nbsp;&nbsp; Shop</small></a>
             <a (click)="routingCall()" class="text-muted cursorpointer"><small style="padding-left: 10px; padding-right: 10px;"><i class="fas fa-user"></i> &nbsp;&nbsp; My Account</small></a>
            </div>
      </div>
</div>
  </div>

</div>


<!-- <nav class="mb-1 navbar navbar-expand-lg navbar-light" style="border-bottom: 2px solid#F1F1F1">
  <div class="container">
    <small class="text-muted">Welcome to BUERO PLANET</small>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent-4"
      aria-controls="navbarSupportedContent-4" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent-4">
      <ul class="navbar-nav ml-auto" >
        <li class="nav-item" style="padding-left: 10px; padding-right: 10px;">
          <a class="nav-link" href="#">
            <small><i class="fas fa-map-marker-alt"></i> &nbsp;&nbsp; Store Locator</small>
          </a>

        </li>
        <li class="nav-item" style="padding-left: 10px; padding-right: 10px;">
          <a class="nav-link" href="#">
            <small><i class="fas fa-shopping-bag"></i> &nbsp;&nbsp; Shop</small>
            </a>
        </li>
        <li class="nav-item" style="padding-left: 10px; padding-right: 10px;">
            <a class="nav-link" href="#">
              <small><i class="fas fa-user"></i> &nbsp;&nbsp; My Account</small>
              </a>
        </li>
      </ul>
    </div>
  </div>

</nav> -->







<nav class="mb-1 navbar navbar-expand-lg navbar-light sticky-top" style="background-color: white;">
  <div class="container">
    <a class="navbar-brand">
      <img class="ml-1" src="/assets/logo.PNG"/>
    </a>
    <button class="navbar-toggler mr-2" type="button" data-toggle="modal" data-target="#sidebarModal"
      aria-controls="navbarSupportedContent-4" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent-4">


      <ul class="navbar-nav ml-auto" >

        <li class="nav-item" style="padding-left: 10px; padding-right: 10px;">
          <div class="input-group">
            <input type="text" class="form-control search-form" placeholder="All Categories">
           <span class="input-group-btn">
             <button type="submit" class="btn search-btn" data-target="#search-form" name="q">
              Search
                                        </button></span>

        </div>

        </li>
        <li class="nav-item" style="padding-left: 10px; padding-right: 10px;">
          <a class="nav-link cursorpointer" (click)="routingCall()">
           <b><i class="fas fa-redo"></i></b>
          </a>

        </li>
        <li class="nav-item" style="padding-left: 10px; padding-right: 10px;">
          <a class="nav-link cursorpointer" (click)="routingCall()">
            <b><i class="far fa-heart"></i></b>
            </a>
        </li>
        <li class="nav-item" style="padding-left: 10px; padding-right: 10px;">
            <a class="nav-link cursorpointer" (click)="routingCall()">
              <b><i class="fas fa-shopping-bag"></i> &nbsp;&nbsp; CH5400</b>
              </a>
        </li>
      </ul>
    </div>
  </div>

</nav>




<div class="container-fluid">
  <div class="modal left fade menu" id="sidebarModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog position-fixed m-auto" role="document">
          <div class="modal-content h-100">
            <div class="input-group">
              <input type="text" class="form-control" placeholder="Search">
              <div class="input-group-append">
                <button class="btn" style="background-color: #ED1B24; color: white;" type="button">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="d-flex justify-content-around">
              <a class="nav-link header_items cursorpointer" (click)="routingCall()"  style="color: #ED1B24"><i class="fas fa-map-marker-alt"></i></a>
              <a class="nav-link header_items cursorpointer" (click)="routingCall()"  style="color:  #ED1B24"><i class="fa fa-heart" aria-hidden="true"></i></a>
              <a class="nav-link header_items cursorpointer"  (click)="routingCall()" style="color:  #ED1B24"><i class="fas fa-shopping-cart"></i></a>
              <a class="nav-link header_items cursorpointer" (click)="routingCall()" style="color: #ED1B24"><i class="fas fa-redo"></i></a>


            </div>

              <div class="modal-body p-3">
                  <div class="nav flex-column">
                      <a class="nav-item nav-link sidebarMenu" (click)="routingCall()" data-dismiss="modal">SUPER DEALS</a>
                      <a  data-dismiss="modal" (click)="routingCall()"  class="nav-item nav-link sidebarMenu cursorpointer" >FEATURED BRANDS</a>
                      <a  data-dismiss="modal"  (click)="routingCall()" class="nav-item nav-link sidebarMenu cursorpointer" >TRENDING STYLES</a>
                      <a  data-dismiss="modal" (click)="routingCall()"  class="nav-item nav-link sidebarMenu cursorpointer" >GIFT CARDS</a>
                      <a  data-dismiss="modal" (click)="routingCall()" class="nav-item nav-link sidebarMenu cursorpointer" >MY ACCOUNT</a>

                  </div>
              </div>
              <div class="modal-footer">
                  <button type="button" class="btn" style="background-color:#ED1B24; color: white;" data-dismiss="modal">Close</button>
              </div>
          </div>
      </div>
  </div>
</div>
