<div class="container-fluid" style="background-color: #F5F5F5;">
  <div class="container pt-5">
    <div class="row pt-4 pb-5" >

      <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 pb-5">
                <img src="/assets/logo.PNG" height="170px" width="170px"/>
                <p class="pt-4 text-muted"><b>Contact Info</b></p>
                <small class="text-muted">All Right Reserved 2020 | Buero Planet</small><br>
                <div class="d-flex justify-content-around text-muted pt-5">
                  <a (click)="routingCall()" class="text-muted cursorPointer"><span><i class="fab fa-facebook-f"></i></span></a>
                  <a (click)="routingCall()" class="text-muted cursorPointer"> <span><i class="fab fa-instagram"></i></span></a>
                  <a (click)="routingCall()" class="text-muted cursorPointer"> <span><i class="fab fa-twitter"></i></span></a>
                  <a (click)="routingCall()" class="text-muted cursorPointer"> <span><i class="fab fa-linkedin-in"></i></span></a>
                  <a (click)="routingCall()" class="text-muted cursorPointer"> <span><i class="fab fa-pinterest-p"></i></span></a>
                  <a (click)="routingCall()" class="text-muted cursorPointer">  <span><i class="fab fa-youtube"></i></span></a>
                  <a (click)="routingCall()" class="text-muted cursorPointer"><span><i class="fab fa-google-plus-g"></i></span></a>


                </div>
      </div>
      <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 text-muted pb-5" style="font-size: 14px;">
               <div class="row">
                <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12">
                  <p ><b>Find It Fast</b></p>
                  <p class="pt-5 cursorPointer" (click)="routingCall()">Laptops & Computers</p>
                  <p class="cursorPointer" (click)="routingCall()">Cameras & Photography</p>
                  <p class="cursorPointer" (click)="routingCall()">Smart Phones & Tablets</p>
                  <p class="cursorPointer" (click)="routingCall()">Video Games & Condoles</p>
                  <p class="cursorPointer" (click)="routingCall()">Tv and Audio</p>
                  <p class="cursorPointer" (click)="routingCall()">Gadgets</p>
                  <p class="cursorPointer" (click)="routingCall()">Car Electronics & GPS</p>
                </div>
                <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12" style="padding-top: 85px;">

                  <p class="cursorPointer" (click)="routingCall()">Laptops & Computers</p>
                  <p class="cursorPointer" (click)="routingCall()">Cameras & Photography</p>
                  <p class="cursorPointer" (click)="routingCall()">Smart Phones & Tablets</p>
                  <p class="cursorPointer" (click)="routingCall()">Video Games & Condoles</p>
                </div>
               </div>


      </div>
      <div class="col-md-2 col-lg-2 col-sm-12 col-xs-12 text-muted" style="font-size: 14px;">
        <p ><b>Customer Care</b></p>
        <p class="pt-5 cursorPointer" (click)="routingCall()">My Account</p>
        <p class="cursorPointer" (click)="routingCall()">Track Your Order</p>
        <p class="cursorPointer" (click)="routingCall()">Wishlist</p>
        <p class="cursorPointer" (click)="routingCall()">Customer Service</p>
        <p  class="cursorPointer" (click)="routingCall()">Returns/Exchange</p>
        <p class="cursorPointer" (click)="routingCall()">FAQs</p>
        <p class="cursorPointer" (click)="routingCall()">Product Support</p>
      </div>
</div>
  </div>
</div>


<div class="container-fluid" style="background-color : #EAEAEA">
  <div class="container">
      <div class="row p-4">
          <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12">
                 <small class="text-muted">Powered By ----</small>
          </div>
          <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12 d-flex justify-content-around">
           <img class="cursorPointer" (click)="routingCall()" src="/assets/footer/visa.png" height="30px" width="50px"/>
           <img class="cursorPointer" (click)="routingCall()" src="/assets/footer/mastercard.png" height="30px" width="50px"/>
           <img class="cursorPointer" (click)="routingCall()" src="/assets/footer/paypal.png" height="20px" width="50px"/>
           <img class="cursorPointer" (click)="routingCall()" src="/assets/footer/discover.png" height="30px" width="70px"/>
          </div>
      </div>
  </div>
</div>
