<div class="container-fluid p-0" style="overflow: hidden;">


<div class="container containerEnd">
  <div class="row pt-3">


    <div (click)="isShowMenu()" class="col-lg-2 mx-3 col-sm-12 col-xs-12 gridMenu text-center"
    style="background-color: #ED1B24; padding: 10px; color: white; cursor: pointer;">
      <i class="fas fa-bars"></i> All Categories
    </div>
    <div class="col menuShow" id="col10" style="background-color: white;">
      <ul class="d-flex justify-content-lg-around justify-content-md-around" style="list-style: none;">
        <li><a (click)="routingCall()" style="color:  #ED1B24; cursor: pointer;"><small><b>Super Deals</b></small></a></li>
        <li><a (click)="routingCall()" class="text-muted cursorpointer"><small><b>Featured Brands</b></small></a></li>
        <li><a (click)="routingCall()" class="text-muted cursorpointer"><small><b>Trending Styles</b></small></a></li>
        <li><a (click)="routingCall()" class="text-muted cursorpointer"><small><b>Gift Cards</b></small></a></li>
      </ul>
    </div>


  </div>
</div>



  <div class="row mainBanner m-0">
    <div class="container">

        <div class="vertical-menu" *ngIf="isBannerMenu">
          <a (click)="routingCall()" class="bannerMenu cursorpointer"><b style="padding-left: 25px;">Software</b></a>
          <a (click)="routingCall()" class="bannerMenu cursorpointer"><b style="padding-left: 25px;">Hardware</b></a>
          <a (click)="routingCall()" class="bannerMenu cursorpointer"><b style="padding-left: 25px;">Buchsotichment</b></a>
          <a (click)="routingCall()" class="bannerMenu cursorpointer"><b style="padding-left: 25px;">Root</b></a>
          <a (click)="routingCall()" class="bannerMenu cursorpointer"><b style="padding-left: 25px;">IT Hardware</b></a>
          <a (click)="routingCall()" class="bannerMenu cursorpointer"><b style="padding-left: 25px;">IT Software</b></a>
          <a (click)="routingCall()" class="bannerMenu cursorpointer"><b style="padding-left: 25px;">Mobiles</b></a>
          <a (click)="routingCall()" class="bannerMenu cursorpointer"><b style="padding-left: 25px;">Laptops</b></a>
          <a (click)="routingCall()" class="bannerMenu cursorpointer"><b style="padding-left: 25px;">Desktop</b></a>
          <a (click)="routingCall()" class="bannerMenu cursorpointer"><b style="padding-left: 25px;">Headphones</b></a>
          <a (click)="routingCall()" class="bannerMenu cursorpointer"><b style="padding-left: 25px;">Watches</b></a>
          <a (click)="routingCall()" class="bannerMenu cursorpointer"><b style="padding-left: 25px;">Air Pods</b></a>
          <a (click)="routingCall()" style="color: #ED1B24;cursor: pointer;"><b style="padding-left: 25px;">View All&nbsp;<i class="fa fa-chevron-right" aria-hidden="true"></i></b></a>

        </div>

    </div>




  </div>

</div>
<!--End Container Fluid-->

<div class="container">

  <div class="row mt-5 mb-5" style="column-gap: 20px; row-gap: 20px;">
    <!-- <div class="col d-flex justify-content-between">
     <img src="/assets/home/camera1.JPG"/>
     <img src="/assets/home/laptop1.JPG"/>
     <img src="/assets/home/desktop1.JPG"/>
 </div> -->
    <div class="col" style="background-color:#F5F5F5">
      <div class="row p-3">
        <div class="col">
          <img src="/assets/home/cam.png" style="height: 100px;" />
        </div>
        <div class="col">
          <p style="font-size: 17px;">CATCH BIG DEALS ON THE CAMERAS</p>
          <a class="shopNowSize cursorpointer" style="color: black;" (click)="routingCall()"><b>Shop now <span class="shopNowBtn">&nbsp;<i
                  class="fas fa-greater-than"></i>&nbsp;</span></b></a>
        </div>
      </div>
    </div>
    <div class="col" style="background-color:#F5F5F5">
      <div class="row p-3">
        <div class="col">
          <img src="/assets/home/lap.png" style="height: 80px;" />
        </div>
        <div class="col">
          <p style="font-size: 17px;">MOBILES AND MORE UPTO 70</p>
          <a class="shopNowSize cursorpointer" style="color: black;"(click)="routingCall()"><b>Shop now <span class="shopNowBtn">&nbsp;<i
                  class="fas fa-greater-than"></i>&nbsp;</span></b></a>
        </div>
      </div>
    </div>
    <div class="col" style="background-color:#F5F5F5">
      <div class="row p-3">
        <div class="col">
          <img src="/assets/home/desk.png" style="height: 100px;" />
        </div>
        <div class="col">
          <p style="font-size: 17px;">SHOP THE HOTTEST PRODECTS</p>
          <a class="shopNowSize cursorpointer" style="color: black;" (click)="routingCall()"><b>Shop now <span class="shopNowBtn">&nbsp;<i
                  class="fas fa-greater-than"></i>&nbsp;</span></b></a>
        </div>
      </div>
    </div>


  </div>

  <div class="row pb-5">
    <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 border">
      <div class="row pt-4 pb-4 d-flex justify-content-between" style="margin-right: 7px; margin-right: 7px;">
        <div class="col-md-12 d-flex justify-content-between">
          <h4 class="pb-2" style="border-bottom: 2px solid #ED1B24;">Special Offer</h4>
          <button type="button" class="viewDetailBtn"><small>Save 20%</small><br>
            <p>CHF58.41</p>
          </button>
        </div>

        <!-- <div class="col-md-6 text-center" style="background-color: #ED1B24; color: white;">
                  <small>Save 20%</small>
                  <p>CHF58.41</p>
              </div> -->
      </div>
      <div class="row text-center">
        <div class="col">
          <img src="/assets/home/joystick.png" height="auto" width="100%" />
          <p style="color: purple; padding: 0px 60px 15px 60px;"><b>Game Console Controller + USB 3.0 Cable</b></p>
          <small></small>
        </div>
      </div>
    </div>
    <div class="col-md-8 col-lg-8 col-sm-12 col-xs-12">
      <div class="row pb-3" style="border-bottom: 2px solid #F1F1F1;">
        <!-- <div class="col-md-2"></div> -->
        <div class="col d-flex justify-content-around mt-3">
          <a (click)="routingCall()" style="font-size: 19px; color: black; cursor: pointer;"><b>Featured</b></a>
          <a (click)="routingCall()" style="font-size: 19px; color: black; cursor: pointer;"><b>On Sale</b></a>
          <a (click)="routingCall()" style="font-size: 19px; color: black; cursor: pointer;"><b>Top Rated</b></a>
        </div>
        <!-- <div class="col-md-2"></div> -->
      </div>
      <div class="row pt-2">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 p-4">
          <small class="text-muted">Audio Speakers</small>
          <br>
          <b>Wireless Audio System resolve 360</b>
          <img class="pt-3" src="/assets/home/lap.png" height="auto" width="100%" />
          <div class="d-flex justify-content-between pt-5">
            <b class="priceText">CHF41.38</b>
            <button type="button" class="viewDetailBtn" (click)="routingCall()"><span class="viewDetailText">View Detail</span></button>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 p-4"
          style="border-left: 2px solid #F1F1F1; border-right: 2px solid #F1F1F1;">
          <small class="text-muted">Laptops</small>
          <br>
          <b>Tablet Red EliteBook Revolve 810 G2</b>
          <img class="pt-3" src="/assets/home/lap.png" height="auto" width="100%" />
          <div class="d-flex justify-content-between pt-5">
            <b class="priceText">CHF41.38</b>
            <button type="button" class="viewDetailBtn" (click)="routingCall()"><span class="viewDetailText">View Detail</span></button>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 p-4">
          <small class="text-muted">HeadPhones</small>
          <br>
          <b>White Solo 2 Wireless System</b>
          <img class="pt-3" src="/assets/home/lap.png" height="auto" width="100%" />
          <div class="d-flex justify-content-between pt-5">
            <b class="priceText">CHF41.38</b>
            <button type="button" class="viewDetailBtn" (click)="routingCall()"><span class="viewDetailText">View Detail</span></button>
          </div>
        </div>
      </div>
      <div class="row pt-4">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 p-4">
          <small class="text-muted">Audio Speakers</small>
          <br>
          <b>Wireless Audio System MultiRoom 360</b>
          <img class="pt-3" src="/assets/home/lap.png" height="auto" width="100%" />
          <div class="d-flex justify-content-between pt-5">
            <b class="priceText">CHF41.38</b>
            <button type="button" class="viewDetailBtn" (click)="routingCall()"><span class="viewDetailText">View Detail</span></button>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 p-4"
          style="border-left: 2px solid #F1F1F1; border-right: 2px solid #F1F1F1;">
          <small class="text-muted">Laptops</small>
          <br>
          <b>Tablet Red EliteBook Revolve 810 G2</b>
          <img class="pt-3" src="/assets/home/lap.png" height="auto" width="100%" />
          <div class="d-flex justify-content-between pt-5">
            <b class="priceText">CHF41.38</b>
            <button type="button" class="viewDetailBtn" (click)="routingCall()"><span class="viewDetailText">View Detail</span></button>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 p-4">
          <small class="text-muted">HeadPhones</small>
          <br>
          <b>White Solo 2 Wireless System</b>
          <img class="pt-3" src="/assets/home/lap.png" height="auto" width="100%" />
          <div class="d-flex justify-content-between pt-5">
            <b class="priceText">CHF41.38</b>
            <button type="button" class="viewDetailBtn" (click)="routingCall()"><span class="viewDetailText">View Detail</span></button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <hr>

  <div class="row pt-5 d-flex justify-content-between bestSellerRow">
    <div>
      <h1 class="bestSellerTitle">Best Seller</h1>
    </div>
    <div class="d-flex justify-content-around">
      <a (click)="routingCall()" class="text-muted bestSellerMenu">Top 20</a>
      <a (click)="routingCall()" class="text-muted bestSellerMenu">SmartPhones & Tablets</a>
      <a (click)="routingCall()" class="text-muted bestSellerMenu">Laptops & Computers</a>
      <a (click)="routingCall()" class="text-muted bestSellerMenu">Video Cameras</a>


    </div>



  </div>


  <div class="row pt-5 pb-5">
    <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 d-flex justify-content-between mt-5 mb-5"
      style="border-right: 2px solid #f1f1f1;">
      <img src="/assets/home/lap.png" height="100px" width="150px" />
      <div>
        <small class="text-muted ">HeadPhones Cases</small>
        <br>
        <b class="captionBestSeller">Wireless Audio System MultiRoom 360</b><br>
        <button type="button" class="viewDetailBtn " (click)="routingCall()">CHF58.41</button>
        <!-- <a href="#"> <span class="cartIcon"><i class="fas fa-shopping-cart"></i></span></a> -->
      </div>
    </div>
    <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 d-flex justify-content-between mt-5 mb-5"
      style="border-right: 2px solid #f1f1f1;">
      <img src="/assets/home/lap.png" height="100px" width="150px" />
      <div>
        <small class="text-muted">HeadPhones Cases</small>
        <br>
        <b class="captionBestSeller">Wireless Audio System MultiRoom 360</b><br>
        <button type="button" class="viewDetailBtn" (click)="routingCall()">CHF58.41</button>
        <!-- <a href="#"> <span class="cartIcon"><i class="fas fa-shopping-cart"></i></span></a> -->
      </div>
    </div>
    <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 d-flex justify-content-between mt-5 mb-5">
      <img src="/assets/home/lap.png" height="100px" width="150px" />
      <div>
        <small class="text-muted">HeadPhones Cases</small>
        <br>
        <b class="captionBestSeller">Wireless Audio System MultiRoom 360</b><br>
        <button type="button" class="viewDetailBtn" (click)="routingCall()">CHF58.41</button>
        <!-- <a href="#"> <span class="cartIcon"><i class="fas fa-shopping-cart"></i></span></a> -->
      </div>
    </div>
    <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 d-flex justify-content-between mt-5 mb-5"
      style="border-right: 2px solid #f1f1f1;">
      <img src="/assets/home/lap.png" height="100px" width="150px" />
      <div>
        <small class="text-muted">HeadPhones Cases</small>
        <br>
        <b class="captionBestSeller">Wireless Audio System MultiRoom 360</b><br>
        <button type="button" class="viewDetailBtn" (click)="routingCall()">CHF58.41</button>
        <!-- <a href="#"> <span class="cartIcon"><i class="fas fa-shopping-cart"></i></span></a> -->
      </div>
    </div>
    <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 d-flex justify-content-between mt-5 mb-5"
      style="border-right: 2px solid #f1f1f1;">
      <img src="/assets/home/lap.png" height="100px" width="150px" />
      <div>
        <small class="text-muted">HeadPhones Cases</small>
        <br>
        <b class="captionBestSeller">Wireless Audio System MultiRoom 360</b><br>
        <button type="button" class="viewDetailBtn" (click)="routingCall()">CHF58.41</button>
        <!-- <a href="#"> <span class="cartIcon"><i class="fas fa-shopping-cart"></i></span></a> -->
      </div>
    </div>
    <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 d-flex justify-content-between mt-5 mb-5">
      <img src="/assets/home/lap.png" height="100px" width="150px" />
      <div>
        <small class="text-muted">HeadPhones Cases</small>
        <br>
        <b class="captionBestSeller">Wireless Audio System MultiRoom 360</b><br>
        <button type="button" class="viewDetailBtn" (click)="routingCall()">CHF58.41</button>
        <!-- <a  href="#"> <span class="cartIcon"><i class="fas fa-shopping-cart"></i></span></a> -->
      </div>
    </div>
  </div>





</div>
<!--End Main Container-->

<div class="container-fluid">

  <div class="row mt-5">
    <div class="col" style="background-image: url('/assets/home/keyboard.jpg');
    background-attachment: fixed; background-size: 100% 100%; height: 250px; width: 100%;">
      <div class="container " style="padding-top: 75px;">

        <div class="row d-flex justify-content-between">
          <div class="col">
            <img src="/assets/home/cannon.JPG" height="auto" width="100%" />
          </div>
          <div class="col">
            <img src="/assets/home/hp.JPG" height="auto" width="100%" />
          </div>
          <div class="col">
            <img src="/assets/home/sony.JPG" height="auto" width="100%" />
          </div>
          <div class="col">
            <img src="/assets/home/panasonic.JPG" height="auto" width="100%" />
          </div>
        </div>

      </div>

    </div>
  </div>

  <div class="row" style="background-color: #ED1B24; color: white;">
    <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12 text-center p-4">
      <h2 class="pt-1"><i class="far fa-envelope"></i>&nbsp; Subscribe Our NewsLetter</h2>
    </div>
    <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12 d-flex justify-content-center text-center p-4">
      <div class="input-group" style="width: 80%">
        <input type="email" class="form-control search-form" placeholder="Email Id">
        <span class="input-group-btn">
          <button type="submit" class="btn search-btn" data-target="#search-form" name="q">
            Submit
          </button></span>

      </div>
    </div>
  </div>



</div>
